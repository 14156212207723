import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Section = styled.section`
  padding: 32px 0;
  max-width: 1300px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 64px 0;
  }
`

export default function AboutUsPageSection() {
  return (
    <Section>
      <h2
        css={theme => css`
          margin-top: 0;
          padding: 0 12px;
          color: ${theme.black};
        `}
      >
        WELCOME TO OUR PRACTICE
      </h2>
      <p
        css={css`
          padding: 0 12px;
        `}
      >
        Chamberlain Dental is a full service dental office with state of the art
        technology offering a variety of services from cosmetic work to implants
        to sedation.
      </p>
      <p
        css={theme => css`
          border-radius: 16px;
          margin: 32px 12px;
          background: ${theme.light};
          padding: 10px;
        `}
      >
        “There is something to be said about having a dream and realizing your
        dream come true--- GRATITUDE. It will be 8 years come this June that we
        saw our first patient (my Mom). Words can not express the gratitude I
        have for our patients and all of the amazing team members at Chamberlain
        Dental. “ - Dr Theodore Chamberlain
      </p>
      <StaticImage
        src="../images/about-us-page.jpg"
        alt="our office"
        placeholder="blurred"
        quality={80}
        css={css`
          max-width: 1130px;
          margin: 0 auto;
          display: block;
        `}
      />
    </Section>
  )
}
