import React from "react"
import AboutUsPageSection from "../../components/AboutUsPageSection"
import Layout from "../../components/layout/Layout"
import Seo from "../../components/layout/Seo"
import OurTeam from "../../components/OurTeam"

export default function AboutUsPage() {
  return (
    <Layout path="/about-us">
      <Seo
        title="About Chamberlain Dental"
        description="About chamberlain dental near clearwater Florida"
      />
      <AboutUsPageSection />
      <OurTeam />
    </Layout>
  )
}
